<template>
	<div class="main">
		<el-breadcrumb separator="/">
			<el-breadcrumb-item>教务</el-breadcrumb-item>
			<el-breadcrumb-item><a style="color: #000000;">教师档案</a></el-breadcrumb-item>
		</el-breadcrumb>
		<div class="u-f-item u-f-jsb head">
			<div class="u-f-item">
				<!-- <el-select v-model="condition" placeholder="校区" size="small" style="width: 150px;margin-right: 10px;" @change="changeSchool">
					<el-option v-for="item in conditionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select> -->
				<el-select v-model="yearval" placeholder="学年" size="small" style="width: 150px;margin-right: 10px;" @change="changeYear">
					<el-option v-for="item in years" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<el-select :disabled="subjectval>0?true:false" v-model="gradeval" :clearable="true" placeholder="年级" size="small" style="width: 150px;margin-right: 10px;" @change="changeGrade">
					<el-option v-for="item in grades" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<el-select :disabled="subjectval>0?true:false" v-model="clbumval" :clearable="true" placeholder="班级" size="small" style="width: 150px;margin-right: 10px;" @change="changeClass">
					<el-option v-for="item in clbums" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<el-select :disabled="gradeval>0||clbumval>0?true:false" v-model="subjectval" :clearable="true" placeholder="科组" size="small" style="width: 150px;margin-right: 10px;" @change="changeSubject">
					<el-option v-for="item in subjects" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</div>
			<div class="main-tab-bars u-f-item">
				<div class="main-tab-item" :class="{ active: tabIndex == 0 }" @click="tabTap(0)">直观视图</div>
				<div class="main-tab-item" :class="{ active: tabIndex == 1 }" @click="tabTap(1)">列表视图</div>
			</div>
		</div>
		<template v-if="tabIndex == 0">
			<el-row :gutter="20">
				<el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
					<div class="main-item">
						<div class="title">男女比例</div>
						<div class="u-f-justify" style="height: 260px;">
							<div style="margin-right: 60px;">
								<Progress strokeColor="#2D8CF0" :transitionDuration="1000" :radius="60" :strokeWidth="15" :value="sex['1']?sex['1']/(sex['1']+sex['2'])*100:0">
									<div style="font-size:26px;color: #2D8CF0;font-weight: 100;">
										{{sex['1']}}
										<span style="font-size: 12px;">人</span>
									</div>
									<template v-slot:footer>
										<div class="u-f-justify" style="color: #94A3AB;">男</div>
									</template>
								</Progress>
							</div>
							<div>
								<Progress strokeColor="#FF7A65" :transitionDuration="1000" :radius="60" :strokeWidth="15" :value="sex['2']?sex['2']/(sex['1']+sex['2'])*100:0">
									<div style="font-size:26px;color: #FF7A65;font-weight: 100;">
										{{sex['2']}}
										<span style="font-size: 12px;">人</span>
									</div>
									<template v-slot:footer>
										<div class="u-f-justify" style="color: #94A3AB;">女</div>
									</template>
								</Progress>
							</div>
						</div>
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
					<div class="main-item">
						<div class="title">学科比例</div>
						<div style="height: 260px;padding: 0 15px;"><VeHistogram :data="discipline" :extend="chartExtend" style="height: 260px;width: 100%;"></VeHistogram></div>
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
					<div class="main-item">
						<div class="title">{{gradeval?'班级':'年级'}}人数比例</div>
						<div style="height: 260px;padding: 0 15px;" v-if="gradeval"><ve-line :data="grade" :extend="chartExtend5" style="height: 260px;width: 100%;"></ve-line></div>
						<div style="height: 260px;padding: 0 15px;" v-if="!gradeval"><ve-line :data="grade" :extend="chartExtend2" style="height: 260px;width: 100%;"></ve-line></div>
					</div>
				</el-col>

				<el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
					<div class="main-item">
						<div class="title">年龄比例</div>
						<div style="height: 260px;padding: 0 15px;">
							<ve-ring height="240px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="age"></ve-ring>
						</div>
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
					<div class="main-item">
						<div class="title">职称比例</div>
						<div style="height: 260px;padding: 0 15px;">
							<ve-ring height="240px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="professional"></ve-ring>
						</div>
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
					<div class="main-item">
						<div class="title">身份比例</div>
						<div style="height: 260px;padding: 0 15px;">
							<ve-ring height="240px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="identity"></ve-ring>
						</div>
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
					<div class="main-item">
						<div class="title">正编/非正编比例</div>
						<div style="height: 260px;padding: 0 15px;">
							<ve-ring height="240px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="staff_type"></ve-ring>
						</div>
					</div>
				</el-col>
			</el-row>
		</template>
		<template v-if="tabIndex == 1">
			<div class="content">
				<div class="u-f-item u-f-jsb">
					<div class="title">共{{total}}条数据</div>
					<div class="u-f-item" style="padding-right: 15px;">
						<el-input size="medium" style="margin-right: 10px;" placeholder="请输入姓名"  prefix-icon="el-icon-search" v-model="keys"></el-input>
						<el-button size="medium" type="primary" @click="sousuo">搜索</el-button>
					</div>
				</div>
				<div style="padding:0 15px;">
					<!-- <div class="u-f-item u-f-jsb" style="padding:10px 15px;background-color: #E6F7FF;border: 1px solid #B7E3FF;margin-bottom: 15px;">
						<div>已选择0项</div>
						<div style="color: #2F9AFF;" class="mouse" @click="toggleSelection">取消选择</div>
					</div> -->
					<el-table ref="multipleTable" :data="List" tooltip-effect="dark" height="630" @selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55"></el-table-column>
						<el-table-column type="index" label="序号"></el-table-column>
						<el-table-column prop="username" label="姓名"></el-table-column>
						<el-table-column prop="gender" label="性别" :filters="[{ text: '男', value: '1' }, { text: '女', value: '2' }]" :filter-method="filtersex">
							<template slot-scope="scope">
								<div>{{scope.row.gender==1?'男':'女'}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="grade_name" label="年级"></el-table-column>
						<el-table-column
							prop="discipline_name"
							label="学科"
							:filters="[{ text: '语文', value: '1' }, { text: '数学', value: '2' }]"
							:filter-method="filtersubject"
						></el-table-column>
						<el-table-column
							prop="class_name"
							label="班级"
							:filters="[{ text: '高一2班', value: '1' }, { text: '高一3班', value: '2' }]"
							:filter-method="filterclasses"
						></el-table-column>
						<el-table-column prop="mobile" label="电话"></el-table-column>
						<el-table-column prop="entry" label="入职" sortable></el-table-column>
						<el-table-column
							prop="staff_type_name"
							label="性质"
							:filters="[{ text: '行政', value: '1' }, { text: '非行政', value: '2' }]"
							:filter-method="filternature"
						></el-table-column>
						<el-table-column
							prop="job_title_name"
							label="职称"
							:filters="[{ text: '无', value: '0' }, { text: '一级', value: '1' }, { text: '中级', value: '2' }, { text: '高级', value: '3' }]"
							:filter-method="filterrank"
						></el-table-column>
						<el-table-column
							prop="identity"
							label="身份"
							:filters="[
								{ text: '群众', value: '1' },
								{ text: '少先队员', value: '2' },
								{ text: '团员', value: '3' },
								{ text: '党员', value: '4' }
							]"
							:filter-method="filteridentity"
						>
							<template slot-scope="scope">
								<div>
									<blockquote style="margin:0" v-if="scope.row.identity==1">群众</blockquote>
									<blockquote style="margin:0" v-else-if="scope.row.identity==2">少先队员</blockquote>
									<blockquote style="margin:0" v-else-if="scope.row.identity==3">团员</blockquote>
									<blockquote style="margin:0" v-else-if="scope.row.identity==4">党员</blockquote>
								</div>
							</template>
						</el-table-column>
						<el-table-column
							prop="salary_name"
							label="工资"
							:filters="[{ text: '一级', value: '1' }, { text: '二级', value: '2' }, { text: '三级', value: '3' }]"
							:filter-method="filtersalary"
						></el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<!-- <el-button @click="handleClick(scope.row)" type="text" size="small">编辑</el-button> -->
								<!-- <span style="color: #E0E0E0;">|</span> -->
								<el-button @click="detailsClick(scope.row)" type="text" size="small">详情</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-right" style="padding: 5px 0;">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="10"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
			</div>
			<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="600px" :before-close="handleClose">
				<div class="u-f-item" style="margin:10px 0 30px 0;">
					<div class="u-f1 u-f-right" style="color: #A4A4A4;">
						<span style="color: red;">*</span>
						入职时间
					</div>
					<div class="u-f6" style="margin-left: 10px;"><el-date-picker v-model="value2" type="month" placeholder="选择月"></el-date-picker></div>
				</div>
				<div class="u-f-item" style="margin:10px 0 30px 0;">
					<div class="u-f1 u-f-right" style="color: #A4A4A4;">
						<span style="color: red;">*</span>
						职称
					</div>
					<div class="u-f6" style="margin-left: 10px;">
						<el-radio-group v-model="rank">
						    <el-radio :label="1">无</el-radio>
						    <el-radio :label="2">一级</el-radio>
						    <el-radio :label="3">中级</el-radio>
							<el-radio :label="4">高级</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="u-f-item" style="margin:10px 0 30px 0;">
					<div class="u-f1 u-f-right" style="color: #A4A4A4;">
						<span style="color: red;">*</span>
						身份
					</div>
					<div class="u-f6" style="margin-left: 10px;">
						<el-radio-group v-model="rank">
						    <el-radio :label="1">群众</el-radio>
						    <el-radio :label="2">团员</el-radio>
						    <el-radio :label="3">党员</el-radio>
							<el-radio :label="4">民主党派</el-radio>
							<el-radio :label="5">其它</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="u-f-item" style="margin:10px 0 30px 0;">
					<div class="u-f1 u-f-right" style="color: #A4A4A4;">
						<span style="color: red;">*</span>
						工资
					</div>
					<div class="u-f6" style="margin-left: 10px;">
						<el-select v-model="value" placeholder="请选择" style="width: 90%;">
						    <el-option
						      v-for="item in options"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value">
						    </el-option>
						  </el-select>
					</div>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
				</span>
			</el-dialog>
		</template>
	</div>
</template>

<script>
import Progress from 'easy-circular-progress';
export default {
	components: {
		Progress
	},
	props: {},
	data() {
		return {
			rank:3,
			tabIndex: 0,
			page: 1,
			total: 0,
			yearval: '',
			years: [],
			gradeval: '',
			grades: [],
			clbumval: '',
			clbums: [],
			subjects: [],
			subjectval: '',
			chartExtend: {
				legend: {
					show: false
				},
				grid: {
					height: 180
				},
				xAxis: {
					type: 'category',
					axisTick: {
						alignWithLabel: true
					},
					axisLabel: {
						textStyle: {
							//文字样式
							color: '#878787'
						}
					}
				},
				yAxis: {
					type: 'value',
					axisLabel: {
						textStyle: {
							//文字样式
							color: '#878787'
						}
					}
				},
				series: {
					name: '比例',
					type: 'bar',
					barWidth: 20,
					itemStyle: {
						normal: {
							color: '#58AFFF'
						}
					}
				}
			},
			chartData: {
				columns: ['name', 'values'],
				rows: []
			},
			chartExtend2: {
				legend: {
					show: false
				},
				grid: {
					height: 180
				},
				xAxis: {
					type: 'category',
					axisTick: {
						alignWithLabel: true
					},
					axisLabel: {
						textStyle: {
							//文字样式
							color: '#878787'
						}
					}
				},
				yAxis: {
					type: 'value',
					axisLabel: {
						textStyle: {
							//文字样式
							color: '#878787'
						}
					}
				},
				series: {
					name: '人数',
					type: 'bar',
					barWidth: 20,
					itemStyle: {
						normal: {
							color: '#77DEFB'
						}
					}
				}
			},
			ringExtend:{
				legend: {
					show: true,
					icon:'circle'
				},
			},
			settings: {
				radius: [45, 60],
				offsetY: 100,
				labelLine: {
					show: false
				},
				label: {
					show: false
				}
			},
			appColor: ['#2788F0', '#FF7A65', '#FFC904', '#3DCDE6', '#A190F7'],
			multipleSelection: [],
			List: [],
			dialogVisible: false,
			dialogTitle: '编辑信息',
			value:'',
			value2:'',
			options:[
				{
					value:1,
					label:'一级'
				},
				{
					value:2,
					label:'二级'
				},
				{
					value:3,
					label:'三级'
				}
			],
			age: {},
			discipline: {},
			grade: {},
			identity: {},
			professional: {},
			sex: {},
			staff_type: {},
			keys:'',
			chartExtend5: {
				legend: {
					show: false
				},
				grid: {
					height: 180
				},
				xAxis: {
					type: 'category',
					axisTick: {
						alignWithLabel: true
					},
					axisLabel: {
						textStyle: {
							//文字样式
							color: '#878787'
						}
					}
				},
				yAxis: {
					type: 'value',
					axisLabel: {
						textStyle: {
							//文字样式
							color: '#878787'
						}
					}
				},
				series: {
					name: '人数',
					type: 'line',
					barWidth: 20,
					itemStyle: {
						normal: {
							color: '#77DEFB'
						}
					}
				}
			},
		};
	},
	mounted() {
		this.getYearList();
		this.getSubjectList();
		this.getGradeList()
	},
	methods: {
		
		// 学年列表
		getYearList(){
			this.$api.setting.getSemesterList().then(res=>{
				if(res.data.code==1){
					this.years = res.data.data;
					if(res.data.data.length){
						if( this.years.filter(item=>item.is_default==2).length>0){
							this.yearval = this.years.filter(item=>item.is_default==2)[0].id;
						}else{
							this.yearval = this.years[0].id
						}
					}else{
						this.yearval = ''
					}	
					this.initData();
				}
			})
		},
		changeYear(e){
			this.yearval = e;
			this.page = 1;
			this.List = [];
			this.initData()
		},
		// 年级列表
		getGradeList(){
			this.$api.setting.getGradeList({}).then(res=>{
				if(res.data.code==1){
					this.grades = res.data.data.rows;
					// this.gradeval = this.grades[0].id;
					if(this.gradeval){
						this.getClassList()
					}
				}
			})
		},
		// 切换年级
		changeGrade(e){
			this.gradeval = e;
			this.page = 1;
			this.List = [];
			this.getClassList()
		},
		// 班级列表
		getClassList(){
			this.$api.setting.getClassList({filter: JSON.stringify({'grade_id': this.gradeval})}).then(res=>{
				this.clbums = res.data.rows;
				this.clbumval = '';
				// this.clbumval = this.clbums[0].id;
				this.initData()
			})
		},
		// 切换班级
		changeClass(e){
			this.clbumval = e;
			this.page = 1;
			this.List = [];
			this.initData()
		},
		// 学科列表
		getSubjectList(){
			this.$api.setting.subjectList({}).then(res=>{
				if(res.data.code==1){
					this.subjects = res.data.data;
				}
			})
		},
		changeSubject(e){
			this.subjectval = e;
			this.page = 1;
			this.List = [];
			this.initData()
		},
		initData(){
			let data = {
				type: this.tabIndex,
				year_id: this.yearval||1,
				page: this.page,
				limit: this.limit
			}
			if(this.keys){
				data.username = this.keys;
			}
			if(this.gradeval||this.clbumval){
				data.grade_id = this.gradeval;
				data.class_id = this.clbumval;
				data.discipline_id = ''
			}
			if(this.subjectval){
				data.discipline_id = this.subjectval;
				data.grade_id = '';
				data.class_id = '';
			}
			this.$api.teachers.teachersList(data).then(res => {
				if (res.data.code == 1) {
					if(this.tabIndex==0){
						let columns = ['name', 'values'];
						this.age = {
							columns: columns,
							rows: res.data.data.age
						};
						this.discipline = {
							columns: columns,
							rows: res.data.data.discipline
						};
						this.grade = {
							columns: columns,
							rows: res.data.data.grade
						};
						this.identity = {
							columns: columns,
							rows: res.data.data.identity
						};
						this.professional = {
							columns: columns,
							rows: res.data.data.professional
						};
						this.sex = res.data.data.gender;
						this.staff_type = {
							columns: columns,
							rows: res.data.data.staff_type
						};
					}else{
						this.List = res.data.data.rows;
						this.total = res.data.data.total;
					}
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		sousuo(){
			this.page = 1;
			this.initData()
		},
		handleClose(done) {
			done();
		},
		// 性别筛选
		filtersex(row, column) {
			return row.sex;
		},
		// 学科筛选
		filtersubject(row, column) {
			return row.subject;
		},
		// 班级筛选
		filterclasses(row, column) {
			return row.classes;
		},
		// 性质筛选
		filternature(row, column) {
			return row.nature;
		},
		// 职称筛选
		filterrank(row, column) {
			return row.rank;
		},
		// 工资筛选
		filtersalary(row, column) {
			return row.salary;
		},
		// 身份筛选
		filteridentity(row, column) {
			return row.identity;
		},
		tabTap(index) {
			this.tabIndex = index;
			this.initData()
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		toggleSelection(rows) {
			if (this.List) {
				this.List.forEach(row => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.page = val;
			this.initData()
		},
		handleClick(row) {
			this.dialogVisible = true;
		},
		detailsClick(row) {
			let obj = {
				id:row.id,
				year_id:this.yearval
			}
			this.$emit('detailsTap', obj);
		}
	}
};
</script>

<style lang="scss">
.main {
	.head {
		background-color: #ffffff;
		padding: 8px 20px;
		margin-top: 15px;
		.main-tab-bars {
			border: 1px solid #dadfe0;
			.main-tab-item {
				height: 30px;
				line-height: 30px;
				width: 100px;
				text-align: center;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			.active {
				color: #67adf4;
				background-color: #e1f3ff;
				border: 1px solid #67adf4;
			}
		}
	}
	.main-item {
		background: #ffffff;
		margin-top: 15px;
		.title {
			padding: 15px;
			font-weight: bold;
			font-size: 16px;
			color: #4e4e4e;
			border-bottom: 1px solid #fafafb;
		}
	}
	.content {
		background-color: #ffffff;
		margin-top: 15px;
		height: 79.3vh;
		overflow: hidden;
		.title {
			padding: 15px;
			font-weight: bold;
			font-size: 16px;
		}
	}
	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.vue-circular-progress .circle__progress {
		stroke-linecap: square;
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 20px;
		.el-radio{
			margin-bottom: 10px;
			margin-top: 10px;
		}
	}
	.el-dialog__footer{
		border-top: 1px solid #EEEEEE;
		text-align: center;
	}
	.el-table__column-filter-trigger{
		.el-icon-arrow-down:before{
			content: '\E790';
			font-size: 16px;
		}
	}
}
</style>
