<template>
	<div id="archives_index">
		<index>
			<archives-main  @detailsTap="detailsTap"></archives-main>
		</index>
	</div>
</template>

<script>
	import index from '@/components/common/index.vue';
	import archivesMain from '@/components/archives/main.vue';
	export default {
		components: {
			index,
			archivesMain
		},
		data() {
			return {
				
			}
		},
		mounted() {
			
		},
		methods: {
			detailsTap(row){
				// console.log(row)
				this.$router.push({
					path: '/archives_details', 
					query: {
						id: row.id,
						campus_id:row.campus_id,
						year_id:row.year_id
					}
				})
			}
		}
	};
</script>

<style>
	
</style>
